<template>
  <div class="content_body" v-loading="loading">
    <div class="nav_header">
      <el-row>
        <el-col :span="20">
          <el-form :inline="true" size="small" @submit.native.prevent @keyup.enter.native="handleSearch">
            <el-form-item label="角色名称">
              <el-input v-model="name" size="small" placeholder="输入角色名称" clearable @clear="handleSearch"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" size="small" @click="handleSearch" v-prevent-click>搜索</el-button>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="4" class="text_right">
          <el-button type="primary" size="small" @click="showAddDialog" v-prevent-click>新增</el-button>
        </el-col>
      </el-row>
    </div>

    <div class="martp_10">
      <el-table size="small" :data="tableData">
        <el-table-column prop="Name" label="角色名称"></el-table-column>
        <el-table-column label="操作" width="80">
          <template slot-scope="scope">
            <el-button type="primary" size="small" @click="showEditDialog(scope.row)" v-prevent-click>编辑</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pad_15 text_right">
        <el-pagination background v-if="paginations.total > 0" @current-change="handleCurrentChange" :current-page.sync="paginations.page" :page-size="paginations.page_size" :layout="paginations.layout" :total="paginations.total"></el-pagination>
      </div>
    </div>
    <!--增加、编辑弹出框-->
    <!-- <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="50%">
            <el-form
                    :model="ruleForm"
                    :rules="rules"
                    ref="ruleForm"
                    label-width="120px"
                    @submit.native.prevent
            >
                <el-form-item label="系统角色" prop="name" label-width="80px">
                    <el-input class="width_220" v-model="ruleForm.name" size="small"></el-input>
                </el-form-item>
                <el-tabs>
                    <el-tab-pane label="PC权限设置">
                        <el-table max-height="450px"
                                  size="small"
                                  ref="multipleTable"
                                  :data="updateTableData"
                                  row-key="Code"
                                  :tree-props="{children: 'Child'}"
                        >
                            <el-table-column type="Checkbox" width="55">
                                <template slot-scope="scope">
                                    <el-checkbox
                                            :checked="scope.row.IsSelected"
                                            @change="handleSelectionChange(scope.row)"
                                    ></el-checkbox>
                                </template>
                            </el-table-column>
                            <el-table-column prop="Name" label="菜单权限"></el-table-column>
                            <el-table-column label="功能权限">
                                <template slot-scope="scope">
                                    <el-checkbox
                                            v-for="(p,index) in scope.row.Permission"
                                            :key="index"
                                            :label="p.Code"
                                            :checked="p.IsSelected"
                                            @change="checkboxChange(p)"
                                    >{{p.Name}}
                                    </el-checkbox>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-tab-pane>
                    <el-tab-pane label="APP权限设置">APP权限设置</el-tab-pane>
                </el-tabs>
            </el-form>
            <div slot="footer">
                <el-button size="small" @click="cancelClick" v-prevent-click>取 消</el-button>
                <el-button size="small" type="primary" @click="addClick" :loading="modalLoading" v-prevent-click>确 定
                </el-button>
            </div>
    </el-dialog>-->

    <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="1000px" @closed="dialogClosed">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" @submit.native.prevent>
        <el-form-item label="角色名称" prop="name" label-width="80px">
          <el-input class="width_220" v-model="ruleForm.name" size="small"></el-input>
        </el-form-item>
      </el-form>
      <el-tabs v-model="flex_li">
        <el-tab-pane name="1" label="PC权限设置">
          <el-table size="small" :data="updateTableData" max-height="450px" :border="true" :show-header="false">
            <el-table-column prop="Name" width="100px"></el-table-column>
            <el-table-column prop="Child" style=" padding:0px" class-name="subMenuTable">
              <template slot-scope="scope">
                <el-table size="small" :data="scope.row.Child" :show-header="false" empty-text=" ">
                  <el-table-column prop="Name" width="120px"></el-table-column>
                  <el-table-column class-name="subMenuTable">
                    <template slot-scope="scope">
                      <el-table size="small" :data="scope.row.Child" :show-header="false" empty-text=" ">
                        <el-table-column width="210px">
                          <template slot-scope="scope">
                            <el-checkbox size="small" :checked="scope.row.IsSelected" :label="scope.row.Name" @change="handleSelectionChange(scope.row)"></el-checkbox>
                          </template>
                        </el-table-column>
                        <el-table-column>
                          <template slot-scope="scope">
                            <el-row>
                              <el-col :span="8" v-for="p in scope.row.Permission" :key="p.Code">
                                <el-checkbox size="small" :checked="p.IsSelected" class="padlt_10" :label="p.Name" @change="checkboxChange(p)"></el-checkbox>
                              </el-col>
                            </el-row>
                          </template>
                        </el-table-column>
                      </el-table>
                      <!-- <el-row>
                              <el-col :span="8" v-for="item in scope.row.Child" :key="item.Code">
                                  <el-row >
                                      <el-col :span="9">
                                          <el-checkbox :checked="item.IsSelected" :label="item.Name" class="padlt_10 padtp_5 padbm_5"></el-checkbox>
                                      </el-col>
                                      <el-col :span="15"  v-for="p in item.Permission" :key="p.Code">
                                        <el-checkbox :checked="p.IsSelected" class="padtp_5 padbm_5" :label="p.Name"></el-checkbox>
                                    </el-col>
                                  </el-row>
                              </el-col>
                      </el-row> -->
                    </template>
                  </el-table-column>
                </el-table>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane  name="2" label="小程序权限设置">
          <el-table size="small" :data="miniMapAppTableData" max-height="450px" :border="true" :show-header="false">
            <el-table-column prop="Name" width="100px"></el-table-column>
            <el-table-column prop="Child" style=" padding:0px" class-name="subMenuTable">
              <template slot-scope="scope">
                <!-- <el-table size="small" :data="scope.row.Child" :show-header="false" empty-text=" ">
                  <el-table-column prop="Name" width="120px"></el-table-column>
                  <el-table-column class-name="subMenuTable">
                    <template slot-scope="scope"> -->
                      <el-table size="small" :data="scope.row.Child" :show-header="false" empty-text=" ">
                        <el-table-column width="210px">
                          <template slot-scope="scope">
                            <el-checkbox size="small" :disabled="checkboxDisabled(scope.row)" :checked="scope.row.IsSelected" :label="scope.row.Name" @change="handleSelectionChange(scope.row)"></el-checkbox>
                          </template>
                        </el-table-column>
                        <el-table-column>
                          <template slot-scope="scope">
                            <el-row>
                              <el-col :span="8" v-for="p in scope.row.Permission" :key="p.Code">
                                <el-checkbox size="small" :checked="p.IsSelected" class="padlt_10" :label="p.Name" @change="checkboxChange(p)"></el-checkbox>
                              </el-col>
                            </el-row>
                          </template>
                        </el-table-column>
                      </el-table>
                    <!-- </template>
                  </el-table-column>
                </el-table> -->
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
      <div slot="footer">
        <el-button size="small" @click="cancelClick" v-prevent-click>取 消</el-button>
        <el-button size="small" type="primary" @click="addClick" :loading="modalLoading" v-prevent-click>保 存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import API from "@/api/KHS/Role/role";

export default {
  name: "RoleMenuPermission",
  data() {
    return {
      loading: false,
      modalLoading: false, // 新增、编辑
      tableData: [],
      name: "",
      // 弹出框状态
      dialogVisible: false,
      flex_li: "1", //type
      type: 1, //编辑或新增 新增1 编辑 0
      ID: "", //角色ID
      title: "系统角色",
      updateTableData: [],
      miniMapAppTableData:[],//小程序权限
      MenuPermission: [], //选中的权限
      jurisdiction: [],
      ruleForm: {
        name: "",
      },
      rules: {
        name: [{ required: true, message: "请输入系统角色", trigger: "blur" }],
      },
      dialogTitle: "",
      //需要给分页组件传的信息
      paginations: {
        page: 1, // 当前位于哪页
        total: 0, // 总数
        page_size: 12, // 1页显示多少条
        layout: "total, prev, pager, next,jumper", // 翻页属性
      },
    };
  },
  methods: {
    // 数据显示
    handleSearch: function () {
      let that = this;
      that.paginations.page = 1;
      that.search();
    },
    // 数据显示
    search: function () {
      let that = this;
      that.loading = true;
      var params = {
        RoleName: that.name,
        PageNum: that.paginations.page,
      };
      API.getRole(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.tableData = res.List;
            that.paginations.page_size = res.PageSize;
            that.paginations.total = res.Total;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 上下分页
    handleCurrentChange(page) {
      var that = this;
      that.paginations.page = page;
      that.search();
    },
    /**  是否可以选择  */
    checkboxDisabled(row){
      if (row.ParentCode == "MP-Appointment") return true;
      if (row.ParentCode == "MP-Customer") return true;
      return false
    },  
    // 新增
    showAddDialog: function () {
      var that = this;
      that.type = 1;
      that.dialogVisible = true;
      that.ruleForm.name = "";
      that.updateTableData = [];
      that.dialogTitle = "新增角色";
      // PC
      that.permissionChange(1);
      // 小程序
      that.permissionChange(2);
    },
    // 编辑
    showEditDialog: function (row) {
      var that = this;
      that.type = 0;
      that.dialogVisible = true;
      var detail = row;
      that.ID = detail.ID;
      that.ruleForm.name = detail.Name;
      that.dialogTitle = "编辑角色";
      // PC
      that.rolePermission(1);
      // 小程序
      that.rolePermission(2);
    },

    //  新增、编辑部分
    // 返回
    cancelClick: function () {
      var that = this;
      that.dialogVisible = false;
    },
    //选择type
    // chooseClick: function () {

    //   var that = this;
    //   // that.flex_li = index;
    //   that.permissionChange();
    // },
    //菜单权限列表 
    /**  type 1 PC 权限 2 小程序权限  */
    permissionChange: function (type) {
      var that = this;
      that.loading = true;
      that.MenuPermission = [];
      var params = {
        MenuPermissionType: type,
      };
      API.getMenuPermission(params)
        .then((res) => {
          if (res.StateCode == 200) {
            if(type == 1){
              that.updateTableData = res.Data;
              that.selectionData(res.Data);
            }
            if(type == 2){
              that.miniMapAppTableData = res.Data;
              that.selectionData(res.Data);
            }
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    selectionData: function (echoList) {
      var that = this;
      echoList.forEach(function (item) {
        item.IsSelected = false;
        if (item.Child) {
          that.selectionData(item.Child);
        }
      });
    },
    //角色菜单权限列表
    /**  type 1 PC 权限 2 小程序权限  */
    rolePermission: function (type) {
      var that = this;
      that.loading = true;
      that.MenuPermission = [];
      that.jurisdiction = [];
      that.updateTableData = [];
      that.miniMapAppTableData = [];
      var params = {
        ID: that.ID,
        MenuPermissionType: type,
      };
      API.getRoleGet(params)
        .then((res) => {
          if (res.StateCode == 200) {
            if(type == 1){
              that.updateTableData = res.Data;
              that.echoData(that.updateTableData);
            }
            if(type == 2){
              that.miniMapAppTableData = res.Data;
              that.echoData(that.miniMapAppTableData);
            }
            
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 回显数据
    echoData: function (echoList) {
      var that = this;
      var MenuPermission = that.MenuPermission;
      echoList.forEach(function (item) {
        // 默认选中 小程序 预约 和顾客管理二级权限
        if (item.ParentCode =="MP-Customer" || item.ParentCode == "MP-Appointment") {
          item.IsSelected = true
        }
        if (item.IsSelected) {
          MenuPermission.push(item.Code);
        }
        if (item.Permission.length > 0) {
          item.Permission.forEach(function (p) {
            if (p.IsSelected) {
              that.jurisdiction.push(p.Code);
            }
          });
        }
        if (item.Child) {
          that.echoData(item.Child);
        }
      });
      that.MenuPermission = MenuPermission;
    },
    // 选择
    handleSelectionChange: function (val) {
      var that = this;
      var MenuPermission = that.MenuPermission;
      val.IsSelected = !val.IsSelected;
      if (val.IsSelected) {
        MenuPermission.push(val.Code);
      } else {
        var index = MenuPermission.indexOf(val.Code);
        MenuPermission.splice(index, 1);
      }
      that.MenuPermission = MenuPermission;
    },
    checkboxChange: function (val) {
      var that = this;
      var jurisdiction = that.jurisdiction;
      val.IsSelected = !val.IsSelected;
      if (val.IsSelected) {
        jurisdiction.push(val.Code);
      } else {
        var index = jurisdiction.indexOf(val.Code);
        jurisdiction.splice(index, 1);
      }
      that.jurisdiction = jurisdiction;
    },
    /**  权限弹窗关闭后调用  */
    dialogClosed(){
      this.MenuPermission = [];
      this.jurisdiction = [];
    },
    //  弹出框确认事件保存 新增、编辑
    addClick: function () {
      var that = this;
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (that.MenuPermission.length == 0) {
            that.$message.error({
              showClose: true,
              message: "请选择权限",
              duration: 2000,
            });
            return false;
          }
          var MenuPermission = that.MenuPermission.concat(that.jurisdiction);
          that.modalLoading = true;
          let para = Object.assign({}, that.ruleForm);
          if (that.type == 1) {
            var params = {
              Name: para.name,
              MenuPermission: MenuPermission,
            };
            API.createRole(params)
              .then(function (res) {
                if (res.StateCode === 200) {
                  that.$message.success({
                    showClose: true,
                    message: "新增成功",
                    duration: 2000,
                  });
                  that.$refs["ruleForm"].resetFields();
                  that.search();
                  that.dialogVisible = false;
                } else {
                  that.$message.error({
                    showClose: true,
                    message: res.Message,
                    duration: 2000,
                  });
                }
              })
              .finally(function () {
                that.modalLoading = false;
              });
          } else {
            var Params = {
              ID: that.ID,
              Name: para.name,
              MenuPermission: MenuPermission,
            };
            API.updateRole(Params)
              .then(function (res) {
                if (res.StateCode === 200) {
                  that.$message.success({
                    showClose: true,
                    message: "编辑成功",
                    duration: 2000,
                  });
                  that.$refs["ruleForm"].resetFields();
                  that.search();
                  that.dialogVisible = false;
                } else {
                  that.$message.error({
                    showClose: true,
                    message: res.Message,
                    duration: 2000,
                  });
                }
              })
              .finally(function () {
                that.modalLoading = false;
              });
          }
        }
      });
    },
  },
  mounted() {
    var that = this;
    that.handleSearch();
  },
};
</script>

<style lang="scss">
.subMenuTable {
  padding: 0px !important;
  .cell {
    padding: 0px;
  }
}
</style>
